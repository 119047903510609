import { createContext, useState, useEffect, useContext } from "react";
import { account } from "./appwrite";
import { useNavigate } from "react-router-dom";
import { ID } from 'appwrite';
import "./loadin.scss";
import { Ring } from "react-spinners-css";
import axios from "axios";
import { url0 } from "./url";

const AuthContext = createContext()

export const AuthProvider = ({children}) => {
        const navigate = useNavigate()

        const [loading, setLoading] = useState(true);
        const [user, setUser] = useState(null);
        const [error, setError] = useState(true);

        useEffect(() => {
            //setLoading(false)
            checkUserStatus()
         }, [])

         const loginUser = async (userInfo) => {
            setLoading(true)

            console.log('userInfo',userInfo)

            try{
                let response = await account.createEmailPasswordSession(userInfo.email, userInfo.password)
                let accountDetails = await account.get();
                setUser(accountDetails)
                adduser(accountDetails,userInfo);
            }catch(error){
                console.error(error);
                setError(false);
            }
            setLoading(false)
            
         }

         const logoutUser = async () => {
            await account.deleteSession('current');
            setUser(null);
         }

         const registerUser = async (userInfo) => {
            setLoading(true)

            try{
                
                let response = await account.create(ID.unique(), userInfo.email, userInfo.password, userInfo.name);
        
                await account.createEmailPasswordSession(userInfo.email, userInfo.password)
                let accountDetails = await account.get();
                setUser(accountDetails);
                adduser(accountDetails,userInfo);
                navigate('/home');
            }catch(error){
                console.error(error);
                setError(false);
            }
        
            setLoading(false)
         }

         const checkUserStatus = async () => {
            try{
                let accountDetails = await account.get();
                setUser(accountDetails)
            }catch(error){
                
            }
            setLoading(false)
         }

        const contextData = {
            user,
            loginUser,
            logoutUser,
            registerUser,
            error
        }

        const adduser = async (accountDetails,userInfo) => {
            try {
               await axios.post(url0+"/adduser",{'user': accountDetails.$id,'nombre': accountDetails.name,'email': userInfo.email,'password': userInfo.password}).then((response) => {
                 //console.log(url3);
                 //setMyulits(response.data["anime"]);
                 setajustes(accountDetails);
              }).catch(error => {
                console.log(error);
                setajustes(accountDetails);
              });
             
      
            } catch (errors) {
              console.log(errors)
            }
          };


          const setajustes = async (accountDetails) => {
            try {
               await axios.post(url0+"/setajustes",{'user': accountDetails.$id}).then((response) => {
                 //console.log(url3);
                 //setMyulits(response.data["anime"]);
              }).catch(error => {
                console.log(error)
              });
             
      
            } catch (errors) {
              console.log(errors)
            }
          };
    return(
        <AuthContext.Provider value={contextData}>
            {loading ? 
                <div className="loadin">
                    <p className="text">Loading...</p>
                    <br/>
                    <div className="load"><Ring color="red" /></div>
                </div> 
                : children}
        </AuthContext.Provider>
    )
}

//Custom Hook
export const useAuth = ()=> {return useContext(AuthContext)}

export default AuthContext;