import { useEffect, useRef } from "react";
import { useState } from "react";
import "./register.scss"
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../api/AuthContext";
import { Alert, Snackbar } from "@mui/material";

const Register = (props) =>{
    const {user, registerUser,error} = useAuth()
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const registrerForm = useRef(null)
    const emailRef = useRef();
    const passwordRef = useRef();

    props.funcNav(false);

    useEffect(() => {
        if (user) {
            navigate("/home");
        }
    });
  
    const handleStart = () => {
      setEmail(emailRef.current.value);
    };
    const handleFinish = () => {
      setPassword(passwordRef.current.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const userInfo = {name,email, password};
        registerUser(userInfo);
      }
    
    const redirect = ()=> {
        return navigate("/");
    }

    useEffect(() => {
        if(error===false){
          setOpen(true);
        }
    },[error]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

  return (
    <div className="register">
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert
                onClose={handleClose}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
            >
                Error de registro
            </Alert>
        </Snackbar>
        <div className="top">
            <div className="wrapper">
            </div>
        </div>
        <div className="container">
            <form onSubmit={handleSubmit} ref={registrerForm}>
                <div className="logo">
                <img
                    className="logoI"
                    src="https://cloudbd.animeflixplus.com/v1/storage/buckets/653dbc7e603cf9649e39/files/653dbcb2600b584372c4/view?project=64a3a406b642bef5b2b9&mode=admin"
                    alt=""
                    />
                </div>
                <input type="name" value={name} placeholder="Nombre"  required
                onChange={(event) => {
                    setName(event.target.value);
                }}/>
                <input type="email" value={email} placeholder="Email" required
                onChange={(event) => {
                    setEmail(event.target.value);
                }}/>
                <input type="password" value={password} placeholder="Password" required minlength="8"
                onChange={(event) => {
                    setPassword(event.target.value);
                }}/>
                <input type="submit"  value="Registrase" className="button" />
                <input type="submit"  value="Iniciar Sesión" className="buttonR" onClick={redirect}/>
            </form>
        </div>
    </div>
  )
}
export default Register