import { Add, Check, InfoOutlined, PlayArrow, VolumeOff, VolumeUp } from "@material-ui/icons";
import "./featuredMobile.scss";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../api/AuthContext";
import { url1 } from "../../api/url";
import axios from "axios";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";

export default function FeaturedMobile() {
    //const [isScrolled,setIsScrolled] = useState(false);
    const { user } = useAuth();
    const [Previewlits,setPreviewlits] = useState([]);
    const myvideo = useRef(null)
    const [muted, setMuted] = useState(true);
    const [play, setPlay] = useState(true);
    const mcurrent=false;
    const pcurrent=false;
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };
    const handleToggleMute = () => setMuted(mcurrent => !mcurrent);
    const handleTogglePlay = () => {
      setPlay(pcurrent => !pcurrent);
      if(play){
        myvideo.current.pause();
      }else{
        myvideo.current.play();
      }
    }
    //console.log(window.scrollY);
    window.onscroll = () =>{
      setPlay(window.scrollY >= 500 ? false : true);
      if(myvideo.current != null){
        if(!play){
          myvideo.current.pause();
        }else{
          myvideo.current.play();
        }
      }
      return () => (window.onscroll = null);
      
    }
    
    useEffect(()=>{
        const getPreviList = async () => {
          try {
             await axios.post(url1+"/preview",{'user': user.$id}).then((response) => {
               //console.log(response.data);
               setPreviewlits(response.data["preview"]);
            }).catch(error => {
              console.log(error)
            });
           
    
          } catch (errors) {
            console.log(errors)
          }
        };
        if (Previewlits.length === 0) {
            getPreviList();
        }
      })
  return (
    <div className="featuredMobile">
      {/*<img width="100%" src={Previewlits.imageUrl} alt="" />*/}
        <img className="img" src={Previewlits.imageUrl} alt="" />
        <div className="degrad" onClick={handleTogglePlay}></div>
        <div className="info">
            <img
            src={Previewlits.titleImageUrl}  
                alt=""
            />
        <div className="buttons">
            <button className="more">
            {Previewlits.mylist === 0 ?<Add /> : <Check className="iconR" />}
                <span>Lista</span>
            </button>
            <Link to={`/anime/${Previewlits.id}`} className="link">
              <button className="play">
                  <PlayArrow />
                  <span>Play</span>
              </button>
            </Link>
            <button className="more" onClick={handleOpen}>
                <InfoOutlined />
                <span>Info</span>
            </button>
            
        </div>
        </div>
        <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <Box className="modalMobile">
                <div className="name"><h2 id="parent-modal-title">{Previewlits.name}</h2></div>
                <br/>
                <div className="desc">
                    <p id="parent-modal-description">
                        {Previewlits.description}.
                    </p>
                </div>
                <div className="inf">
                    <h3>Editora</h3>{Previewlits.editoria}
                    <br/>
                    <br/>
                    <h3>Audio</h3>{Previewlits.audio}
                    <br/>
                    <br/>
                    <h3>Subtitulos</h3>{Previewlits.subtitulos}
                </div>
                
                
            </Box>
      </Modal>
    </div>
  )
}
