import { Facebook, Instagram,PhoneAndroidOutlined,Twitter,YouTube } from "@material-ui/icons"
import "./footer.scss"
import { Link } from "react-router-dom"

export default function Footer() {
  return (
    <div className="footer">
        <div className="social">
            <Facebook className="icons"/>
            <Instagram className="icons"/>
            <Twitter className="icons"/> 
            <YouTube className="icons" />
            <a href="https://play.google.com/store/apps/details?id=com.animeflix.animeflix" target="_blank">
            <PhoneAndroidOutlined className="icons" />
            </a>
        </div>
        <ul className="footer_member_links">
            <li><Link to="privacidad" className="link">Politica de privacidad</Link></li>
            <li><a href="mailto:contact@animeflixplus.com" target="_blank" className="link">Contáctanos</a></li>
            <li><a href="https://legal.animeflixplus.com" target="_blank" className="link">Términos de uso</a></li>
        </ul>
        <ul className="footer_member_links">
            <li><Link to="cookies" className="link">Politica de Cookies</Link></li>
            <li></li>
            <li></li>
        </ul>
        <div className="footer_copyright">© 2024 AnimeflixPlus.</div>
    </div>
  )
}
