import { AccountCircle, ArrowDropDown, Notifications, Search } from "@material-ui/icons"
import "./navbar.scss"
import { useState } from "react"
import { Link } from "react-router-dom";
import { useAuth } from "../../api/AuthContext";
import { SearchBar } from "./SearchBar/SearchBar";
import Notification from "./Notification/Notification";
import Perfil from "./Perfil/Perfil";

const Navbar = () => {
    const [isScrolled,setIsScrolled] = useState(false);
    //const { logoutUser } = useAuth();

    /*window.onscroll = () =>{
        setIsScrolled(window.pageYOffset === 0 ? false : true);
        return () => (window.onscroll = null);
    }*/
    window.addEventListener('scroll', scrll);

    function scrll(){
        setIsScrolled(window.pageYOffset === 0 ? false : true);
    }

    //console.log(setIsScrolled);
  return (
    <div className={isScrolled ? "navbar scrolled" : "navbar"}>
        <div className="container">
            <div className="left">
                <img src="https://cloudbd.animeflixplus.com/v1/storage/buckets/653dbc7e603cf9649e39/files/653dbcb2600b584372c4/view?project=64a3a406b642bef5b2b9&mode=admin" 
                alt=""
                />
                <Link to="/home" className="link">
                    <span>Home</span>
                </Link>
                <Link to="/milista" className="link">
                    <span>Mi Lista</span>
                </Link>
            </div>
            <div className="right">
                <SearchBar/>
                <Notification/>
                <Perfil/>
            </div>
        </div>
    </div>
  )
}

export default Navbar
