import { useEffect, useRef } from 'react';

export default function NativeBanner({keyid}) {
    const nativebanner = useRef();

    const atOptions = {
        key: keyid,
        format: 'iframe',
        height: 50,
        width: 320,
        params: {},
    }
    useEffect(() => {
    if (nativebanner.current && !nativebanner.current.firstChild) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = 'async'
        script.src = `//pl23827845.highrevenuenetwork.com/${atOptions.key}/invoke.js`



        nativebanner.current.append(script)
    }
}, [nativebanner])

    return <div id="container-f2a666af8703ebe8b4d62cc4781cab13" ref={nativebanner}></div>
}
