import { useEffect, useState } from "react";
import Featured2 from "../../components/featured/Featured2";
import List from "../../components/list/List";
import Navbar from "../../components/navbar/Navbar";
import { useAuth } from "../../api/AuthContext";
import { url3,url2,socketMilist,url17 } from "../../api/url";
import "./home.scss";
import axios from "axios";
import MyList from "../../components/myList/MyList";
import Footer from "../../components/footer/Footer";
import GoogleAd from "../../api/GoogleAd";
import Navbar2 from "../../components/navbar/Navbar2";
import List2 from "../../components/list/List2";
import { useNavigate } from "react-router-dom";
import NewCList from "../../components/newCapitulos/NewCList";


const Home2 = (props) => {
  const [Categoriaslits,setCategoriaslits] = useState([]);
  const [Animelits,setAnimelits] = useState([]);
  const [Myulits,setMyulits] = useState([]);
  const { user } = useAuth();
  const [newCaplits,setNewCaplits] = useState([]);
  const [oldCaplits,setOldCaplits] = useState([]);
  const [newCapStatus,setNewCapStatus] = useState(false);
  const navigate = useNavigate();
  localStorage.setItem("Pantalla","Home");
  props.funcNav(false);

  useEffect(() => {
    if (user) {
        navigate("/home");
    }
  });

  useEffect(()=>{
    const getHomeList = async () => {
      try {
         await axios.post(url3+"/home2").then((response) => {
           //console.log(url3);
           setAnimelits(response.data["anime"]);
           setCategoriaslits(response.data["categorias"]);
        }).catch(error => {
          console.log(error)
        });
       

      } catch (errors) {
        console.log(errors)
      }
    }


    if (Categoriaslits.length === 0) {
      getHomeList();
    }
  });

  useEffect(async ()=>{
    const getNewCList = async () => {
      try {
         await axios.get(url17+"/addnewcap").then((response) => {
           //console.log(url3);
           setNewCaplits(response.data["newcap"]);
           setNewCapStatus(true);
        }).catch(error => {
          console.log(error)
        });
       

      } catch (errors) {
        console.log(errors)
      }
    }

    const getOldCList = async () => {
      try {
         await axios.get(url17+"/addnewcapold").then((response) => {
           //console.log(url3);
           setOldCaplits(response.data["newcap"]);
           setNewCapStatus(true);
        }).catch(error => {
          console.log(error)
        });
       

      } catch (errors) {
        console.log(errors)
      }
    }

    if (!newCapStatus) {
      getNewCList();
      getOldCList();
    }
    
  });
 

  return (
    <div className='home'>
        <Navbar2/>
        <Featured2/>
        <div className="banner">
          <GoogleAd slot="9418170229" googleAdId="ca-pub-8000262184336490"/>
        </div>
        {newCaplits.length > 0 ? <NewCList capitulos={newCaplits}  titulo="Capítulos de hoy"/>:""}
        {oldCaplits.length > 0 ? <NewCList capitulos={oldCaplits}  titulo="Capítulos de ayer"/>:""}
        {Categoriaslits.map((list) => (
          <List2 categorias={list} anime={Animelits} key={list.id}/> 
        ))}
    </div>
  )
}

export default Home2

