import { ArrowBackOutlined, ThumbDown, ThumbDownOutlined, ThumbUp, ThumbUpAltOutlined, Visibility } from "@material-ui/icons"
import "./watch.scss"
import Navbar from "../../components/navbar/Navbar"
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { url7,url8,url13,url9,urlHls,url18,socketNoti } from "../../api/url";
import { useAuth } from "../../api/AuthContext";
import axios from "axios";
import ReactHlsPlayer from "react-hls-player";
import Banner from "../../components/utils/Banner";
import NativeBanner from "../../components/utils/NativeBanner";

export default function Watch() {
  const { user } = useAuth();
  const { id,noti } = useParams();
  console.log(id);
  console.log(noti);
  localStorage.setItem("Pantalla","Watch");

  const [Capitulo,setCapitulo] = useState([]);
  const [url,setUrl] = useState("");
  const [url2,setUrl2] = useState("");
  const [sta,setSta] = useState(false);

  const [viewcap,setViewcap] = useState(null);
  const [viewcapx,setViewcapx] = useState(0);

  const [anime,setAnime] = useState("");
  const [name,setName] = useState("");

  const [likecapitulo,setLikecapitulo] = useState(0);
  const [likecountcapitulo,setLikecountcapitulo] = useState(0);
  const [dislikecapitulo,setDislikecapitulo] = useState(0);
  const [dislikecountcapitulo,setDislikecountcapitulo] = useState(0);
  const [viewcapitulo,setViewcapitulo] = useState(0);
  const [viewcountcapitulos,setViewcountcapitulos] = useState(0);

  useEffect(async ()=>{
    const getCap= async () => {
      try {
         await axios.post(url7+"/capitulo",{'user': user.$id,'capitulo':id}).then((response) => {
           //console.log(response.data);
           setCapitulo(response.data["capitulos"]);
        }).catch(error => {
          console.log(error)
        });
       

      } catch (errors) {
        console.log(errors)
      }
    };


    //const oldIframe = document.querySelector('iframe');
    //console.log("oldIframe:"+oldIframe);
    /*const gethls= async () => {
      try {
         /*await axios.get(Capitulo[0].videoUrl).then((response) => {
           console.log(response.data);
           //setCapitulo(response.data["capitulos"]);
        }).catch(error => {
          console.log(error)
        });
       
     
      } catch (errors) {
        console.log(errors);
        console.log(errors.response.data);
      }
    };*/
    

    if (Capitulo.length === 0) {
      await getCap();
    }
    
    if(Capitulo.length > 0 && !sta){
      setUrl(Capitulo[0].videoUrl.replace('https://filemoon.sx/d', 'https://animeflixplus.site/e'))
      
      setSta(true);
    }

    
  });

  useEffect(() =>{
    const id = url.split('https://animeflixplus.site/e');
    setUrl2(urlHls+"/filemoon.php?id="+id);
    const getUrlHls = async () => {
      try {
        await axios.get(urlHls+"/filemoon.php?id="+id).then(async (response) => {
          console.log(response.data);
          setUrl2(response.data);
       }).catch(error => {
         console.log(error)
       });
      
  
     } catch (errors) {
       console.log(errors)
     }
    };

    /*if(id.length > 0){
      //getUrlHls();
    }*/
    

  },[url]);

  useEffect(() => {
    const getAni = async () => {
      try {
         await axios.post(url13+"/anime",{'user': user.$id,'idanime':Capitulo[0].anime}).then((response) => {
           //console.log(response.data);
           setAnime(response.data["anime"]);
        }).catch(error => {
          console.log(error)
        });
       

      } catch (errors) {
        console.log(errors)
      }
    };
    if(Capitulo.length > 0){
      setLikecapitulo(Capitulo[0].likecapitulo);
      setLikecountcapitulo(Capitulo[0].likecountcapitulo);
      setDislikecapitulo(Capitulo[0].dislikecapitulo);
      setDislikecountcapitulo(Capitulo[0].dislikecountcapitulo);
      setViewcapitulo(Capitulo[0].viewcapitulo);
      setViewcountcapitulos(Capitulo[0].viewcountcapitulos);
      setName(Capitulo[0].name);
      getAni();
      if(viewcap === null){
        setViewcap(Capitulo[0].viewcapitulo);
        setViewcapx(1);
      }
      if(viewcap === 0){
        setViewcap(1);
        setViewcapx(1);
        setViewcountcapitulos(viewcountcapitulos+1);
      }
    }
  },[Capitulo]);

  useEffect(() => {
    const setView = async () => {
      try {
         await axios.post(url8+"/viewcapitulos",{'user': user.$id,'capitulo':id,'minute':0,'second':0}).then(async (response) => {
           console.log(response.data);
        }).catch(error => {
          console.log(error)
        });
       

      } catch (errors) {
        console.log(errors)
      }
    };

    console.log("userEffect2:"+viewcap);
    if(viewcap === 0){
      setView();
      console.log("Viewani:"+viewcap);
      console.log("View");
    }

  },[viewcap]);


  useEffect(()=>{
    const viewNoti = async (idnoti) => {
      try {
          await axios.post(url18+"/viewnoti",{'user': user.$id,'idnoti':noti}).then(async (response) => {
              console.log(response.data);
              const websocket = new WebSocket(socketNoti);

              // conectado
              websocket.onopen = () => {
              console.log('connected');
              //sendMessage();
                  sendMessage();
              }
              const sendMessage = () => {
              websocket.send("cod:noti");
              };
              
              // al recibir un mensaje
              websocket.onmessage = (event) => {
              const data = JSON.parse(event.data);
              // auto-crear la queryKey a partir del evento recibido
              //console.log(data);
              websocket.close();
              console.log('close');
              }
          }).catch(error => {
          console.log(error)
          });
      } catch (errors) {
          console.log(errors)
      }
    };

    if(noti != null){
      viewNoti();
    }

  },[noti]);

  const addlikeCapitulo = async (status) => {
    try {
       await axios.post(url9+"/removelikecapitulos",{'user': user.$id,'capitulo':id,'status':status});
       await axios.post(url9+"/addlikecapitulos",{'user': user.$id,'capitulo':id,'status':status}).then(async (response) => {
         console.log(response.data);
         
         
      }).catch(error => {
        console.log(error)
      });
     
      if(status===1){
        setLikecapitulo(1);
        setLikecountcapitulo(likecountcapitulo+1);
        if(dislikecountcapitulo != 0){
          setDislikecapitulo(0);
          setDislikecountcapitulo(dislikecountcapitulo-1);
        }
      }else if(status=== 0){
        setDislikecapitulo(1);
        setDislikecountcapitulo(dislikecountcapitulo+1);
        if(likecountcapitulo != 0){
          setLikecapitulo(0);
          setLikecountcapitulo(likecountcapitulo-1);
        }
      }
    } catch (errors) {
      console.log(errors)
    }
  };

  const removelikeCapitulo = async (status) => {
    try {
       await axios.post(url9+"/removelikecapitulos",{'user': user.$id,'capitulo':id,'status':status}).then(async (response) => {
         console.log(response.data);
         if(status===1){
          setLikecapitulo(0);
          setLikecountcapitulo(likecountcapitulo-1);
         }else if(status===0){
          setDislikecapitulo(0);
          setDislikecountcapitulo(dislikecountcapitulo-1);
         }
         
      }).catch(error => {
        console.log(error)
      });
     

    } catch (errors) {
      console.log(errors)
    }
  };

  

  return (
    <div className="watch">
      <div className="video">
        <iframe src={url} className="iframev" height="100%" width="100%"/>
        
      </div>
        {/*<ReactHlsPlayer
        src={url2}
        autoPlay={true}
        controls={true}
        className="iframev"
      />*/}
      <div className="banner">
        {/*<Banner keyid="14f72602cdc47f369711731efc735654"/>*/} 
      </div>
      <div className="info">
        <div className="anime">{anime.name}</div>
        <div className="name">{name}</div>
      </div>
      <div className="iconCap">
        {likecapitulo === 0 ? <ThumbUpAltOutlined className="icon like" onClick={() => addlikeCapitulo(1)}/> : <ThumbUp className="iconR" onClick={() => removelikeCapitulo(1)}/>}
        <div className="spacin"></div>
        {dislikecapitulo === 0 ? <ThumbDownOutlined className="icon" onClick={() => addlikeCapitulo(0)}/> : <ThumbDown  className="iconR" onClick={() => removelikeCapitulo(0)}/>}
        <div className="spacin"></div>
        {viewcapx === 0 ? <Visibility className="icon" /> : <Visibility  className="iconR" />}
      </div>
      <div className="count">
        {likecountcapitulo}
        <div className="spacin"></div>
        {dislikecountcapitulo}
        <div className="spacin"></div>
        {viewcountcapitulos}
      </div>
      <div className="nativeBanner">
        {/*<NativeBanner keyid="f2a666af8703ebe8b4d62cc4781cab13"/>*/}  
      </div> 
    </div>
  )
}
