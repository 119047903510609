import React, { Component, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from "react";
import { useAuth } from "../../api/AuthContext";
import "./login.scss";
import { Alert, Snackbar } from '@mui/material';
import Navbar2 from '../../components/navbar/Navbar2';




const Login = (props) => {
  const {user, loginUser,error} = useAuth()
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  props.funcNav(false);

  const loginForm = useRef(null)

  useEffect(() => {
    if (user) {
        navigate("/home");
    }
});

  const handleSubmit = (e) => {
    e.preventDefault()
    const userInfo = {email, password};
    loginUser(userInfo);
    
  }


  useEffect(() => {
    if(error===false){
      setOpen(true);
    }
  },[error]);

  const redirect = ()=> {
    return navigate("/register");
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpen(false);
    }; 

  return (
    <div className="lg">
      <Navbar2/>
      <div className="login">
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert
                  onClose={handleClose}
                  severity="error"
                  variant="filled"
                  sx={{ width: '100%' }}
              >
                  Email o Password invalida
              </Alert>
          </Snackbar>
        <div className="top">
          <div className="wrapper">
          </div>
        </div>
        <div className="container">
          <form onSubmit={handleSubmit} ref={loginForm}>
          <div className="logo">
            <img
                className="logoI"
                src="https://cloudbd.animeflixplus.com/v1/storage/buckets/653dbc7e603cf9649e39/files/653dbcb2600b584372c4/view?project=64a3a406b642bef5b2b9&mode=admin"
                alt=""
              />
          </div>
            <input type="email" value={email} placeholder="Email" required
            onChange={(event) => {
              setEmail(event.target.value);
            }}/>
            <input type="password" value={password} placeholder="Password" required
            onChange={(event) => {
              setPassword(event.target.value);
            }}/>
            <input type="submit"  value="Iniciar" className="button" />
            <input type="submit"  value="Registrarse" className="buttonR" onClick={redirect}/>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login
